import styled from 'styled-components'
import { grayscale, white, black } from '../../styles/colors'

export const Section = styled.div`
  margin-top: 18px;

  h2 {
    color: #2e3038;
  }
  
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      button {
        border: 1px solid ${grayscale[200]};
        border-radius: 20px;
        padding: 5px 15px 5px 15px;
        background-color: ${white};
        float: left;
        margin: 0 6px 6px 0;
        color: ${grayscale[500]};
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      span {
        width: 16px;
        height: 16px;
        background-color: ${black};
        border-radius: 20px;
        color: ${white};
        margin-left: 5px;
        position: relative;
        float: right;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`
