import styled from 'styled-components'
import breakpoints from '../../../../styles/breakpoints'
import { orange } from '../../../../styles/colors'

export const Section = styled.section`
  background-color: ${orange.default};
  padding-top: 79px;

  h1 {
    span {
      font-family: 'Citrina', Helvetica, sans-serif;
    }
  }

  @media(min-width: ${breakpoints.md}) {
    min-height: 415px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/carreiras-vagas/image.webp');
    background-size: auto;
    background-repeat: no-repeat;
    background-position: bottom right;
    padding-top: 118px;
  }
  @media(min-width: ${breakpoints.lg}) {
    min-height: 384px;
    background: url('https://central-imagens.bancointer.com.br/images-without-small-versions/vagas-home-web/image.webp');
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-color: ${orange.default};
    padding-top: 148px;
  }
  @media(min-width: ${breakpoints.xl}) {
    min-height: 474px;
    background-size: 700px;
  }

 
`
