import React from 'react'

import * as S from './style'

interface ISearchFamilyProps {
  JobsContent: { value: string }[];
  handleClickCargo: (cargo: string) => void;
  selectedCargos: string[];
}

const Cargo = ({ JobsContent, handleClickCargo, selectedCargos }: ISearchFamilyProps) => {
  const cargoValue = JobsContent.map((job: { value: string }) => job.title)
  const combinedCargoValues = [].concat(...cargoValue)

  const uniqueCargoValue = combinedCargoValues.filter((value: string, index: number, self: string[]) => self.indexOf(value) === index)

  const cargoCount = combinedCargoValues.reduce((acc: { [key: string]: number }, value: string) => {
    acc[value] = (acc[value] || 0) + 1
    return acc
  }, {})

  const formatCargo = (cargo: string) => {
    return cargo.split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase().replace('iii', 'III').replace('ii', 'II').replace('i', 'I')).join(' ')
  }

  return (
    <S.Section>
      <ul>
        {uniqueCargoValue.map((cargo: string) => (
          <li key={cargo} className='fs-12'>
            <button
              key={cargo}
              onClick={() => handleClickCargo(cargo)}
              style={{
                backgroundColor: selectedCargos.includes(cargo) ? 'black' : 'white',
                color: selectedCargos.includes(cargo) ? 'white' : 'black',
              }}
            >
              {formatCargo(cargo)} <span>{cargoCount[cargo]}</span>
            </button>
          </li>
        ))}
      </ul>
    </S.Section>
  )
}

export default Cargo
