import React, { ChangeEvent, useRef, useEffect } from 'react'
import { useLocation } from '@reach/router' //

// Hooks
import useDataLayer from '../../hooks/useDataLayer/dataLayerBody'

// Style
import GeneralSearchStyle from './style'

type GeneralSearchProps = {
  styles?: string;
  placeholderText?: string;
  searchText: string;
  setSearchText: (text: string) => void;
}

const GeneralSearch = ({ styles, placeholderText, searchText, setSearchText }: GeneralSearchProps) => {
  const textInput = useRef(null)
  const location = useLocation()
  const [ sendDatalayerEvent ] = useDataLayer()

  useEffect(() => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)
    const q = params.get('q')
    setSearchText(q || '')
  })

  const carreirasPage = location.pathname.includes('/carreiras')

  function handleChange (evt: ChangeEvent<HTMLInputElement>) {
    const valueInput = evt.target.value
    setSearchText(valueInput)
    carreirasPage && window.history.pushState('', '', `/carreiras?&q=${encodeURIComponent(valueInput)}`)
    sendDatalayerEvent({
      section: 'dobra_2',
      element_action: 'click button',
      element_name: 'Busca',
      element_search: evt.target.value,
      section_name: '',
    })
  }

  return (
    <GeneralSearchStyle id='search-form' className={styles}>
      <input
        type='text'
        placeholder={placeholderText}
        className='pl-5 fs-16 fs-sm-14 lh-17'
        maxLength={150}
        onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
        value={searchText}
        ref={textInput}
      />
    </GeneralSearchStyle>
  )
}

GeneralSearch.defaultProps = {
  initialValue: '',
  styles: '',
}

export default GeneralSearch
