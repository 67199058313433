import React from 'react'

import * as S from './style'

interface ISearchFamilyProps {
  JobsContent: IAreaValueProps[];
  handleClickAreas: (area: string) => void;
  selectedAreas: string[];
}

interface IAreaValueProps {
  value: string;
  metadata: { value: string }[];
}

const AreasDeAtuacao = ({ JobsContent, handleClickAreas, selectedAreas }: ISearchFamilyProps) => {
  const areaValue = JobsContent.map((job: IAreaValueProps) => job.metadata[1].value)
  const combinedAreaValues = areaValue.flat()

  const uniqueAreaValue = combinedAreaValues.filter((value: string, index: number, self: string[]) => self.indexOf(value) === index)

  const areaCount: { [key: string]: number } = combinedAreaValues.reduce((acc: { [key: string]: number }, value: string) => {
    acc[value] = (acc[value] || 0) + 1
    return acc
  }, {})

  return (
    <S.Section>
      <ul>
        {areaValue && uniqueAreaValue.map((area: string) => (
          area && (
            <li key={area} className='fs-12'>
              <button
                onClick={() => handleClickAreas(area)}
                style={{
                  backgroundColor: selectedAreas.includes(area) ? 'black' : 'white',
                  color: selectedAreas.includes(area) ? 'white' : 'black',
                }}
              >
                {area} <span>{areaCount[area]}</span>
              </button>
            </li>
          )
        ))}
      </ul>
    </S.Section>
  )
}

export default AreasDeAtuacao
