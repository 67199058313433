import React from 'react'
import ImageWebp from '../../../../components/ImageWebp/index'

import useWidth from '../../../../hooks/window/useWidth'

import { Section } from './style'

const WIDTH_MD = 768

const Hero = () => {
  const width = useWidth(300)

  return (
    <Section
      role='img'
      aria-label='Homem e mulher sentados em um sofá laranja e sorrindo enquanto olham para seus computadores'
      className='pb-5 d-flex align-items-md-center'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-5'>
            <h1 className='fs-28 fs-md-32 lh-33 lh-md-38 fw-500 mb-2 mb-md-4 text-white text-center text-md-left'>
              <span className='d-xl-block'>Venha destravar</span> possibilidades
            </h1>
            <p className='fs-14 fs-md-16 lh-16 lh-md-19 text-white text-center text-md-left mt-3'>
              O Inter está sempre crescendo, e para isso, precisamos de um time que esteja de olho no futuro. Confira nossas vagas!
            </p>
          </div>
          <div className='col-12 d-flex justify-content-center mt-3'>
            {width < WIDTH_MD && (
              <ImageWebp
                arrayNumbers={[ 312, 0, 0, 0 ]}
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/vagas-home-mobile/image.webp'
                altDescription=''
              />
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
