import React, { useState, useEffect, ChangeEvent } from 'react'
import QueryString from 'query-string'
import api from '../../services/api'
import { useLocation } from '@reach/router'

// components
import Layout from '../../components/Layout'
import GeneralSearch from '../../components/GeneralSearch/index'
import AreasDeAtuacao from '../../components/AreasDeAtuacao/index'
import Cargo from '../../components/Cargo/index'
import SelectJobs from './SelectJobs/index'
import JobList from './JobList/index'

// Sections
import Hero from './sections/hero/_hero'

// hooks
import useDomReady from '../../hooks/window/useDomReady'

// PageContext
import pageContext from '../pageContext.json'

import * as S from './style'

import { apiData } from './types'

const Carreiras = () => {
  const [ queryString, setQueryString ] = useState<QueryString.ParsedQuery<string>>({})
  const [ openJobs, setOpenJobs ] = useState<apiData[]>([])
  const [ searchText, setSearchText ] = useState('')
  const [ area, setArea ] = useState<string>('')
  const [ jobsLocation, setJobsLocation ] = useState<string>()
  const [ selectedAreas, setSelectedAreas ] = useState<string[]>([])
  const [ selectedCargos, setSelectedCargos ] = useState<string[]>([])
  const [ modal, setModal ] = useState(true)
  const location = useLocation()

  const locationJob = openJobs.map((item: apiData) => item.location.name.toLowerCase())
  const locationJobUnique = locationJob.filter((item: string, index: number) => locationJob.indexOf(item) === index)
  const domReady = useDomReady()
  const JobsContent = openJobs

  const handleClose = () => {
    setSearchText('')
    setQueryString({})
    const url = new URL(window.location.href)
    url.search = ''
    window.history.replaceState({}, document.title, url.toString())
  }

  useEffect(() => {
    api.get('/jobs')
      .then((response: { data: { jobs: apiData[] } }) => setOpenJobs(response.data.jobs))
      .catch((err: Error) => {
        console.log('Ops! Ocorreu um erro' + err)
      })
  }, [])

  useEffect(() => {
    if (domReady) {
      setQueryString(QueryString.parse(window.location.href) as QueryString.ParsedQuery<string>)
      setSearchText(queryString.q as string || '')
    }
  }, [ domReady ])

  useEffect(() => {
    const query = QueryString.parse(location.search)
    if (query.q) {
      setSearchText(query.q as string)
    }
  }, [ location.search ])

  useEffect(() => {
    const query = QueryString.parse(location.search)
    if (query.jobsLocation) {
      setJobsLocation(query.jobsLocation as string)
    }
  }, [ location.search ])

  const handleClickAreas = (area: string) => {
    setSelectedAreas((prevSelectedAreas: string[]) => {
      if (prevSelectedAreas.includes(area)) {
        return prevSelectedAreas.filter((selectedArea: string) => selectedArea !== area)
      } else {
        return [ ...prevSelectedAreas, area ]
      }
    })
  }

  const handleClickCargo = (cargo: string) => {
    setSelectedCargos((prevSelectedCargos: string[]) => {
      if (prevSelectedCargos.includes(cargo)) {
        return prevSelectedCargos.filter((selectedCargo: string) => selectedCargo !== cargo)
      } else {
        return [ ...prevSelectedCargos, cargo ]
      }
    })
  }

  const handleSelectLocation = (evt: ChangeEvent<HTMLSelectElement>) => {
    setJobsLocation(evt.target.value)
  }

  console.log('jobsLocation', jobsLocation)

  return (
    <Layout pageContext={pageContext}>
      <Hero />
      <section className='py-5 bg-grayscale--100'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-md-5 col-xl-4'>
              <h2 className='fs-16 lh-19 fw-600 mb-2 mb-md-0'>Filtros</h2>
              <GeneralSearch
                placeholderText='Pesquisar vagas'
                searchText={searchText}
                setSearchText={setSearchText}
                styles='col-12 px-0'
              />
              <SelectJobs
                locationJobUnique={locationJobUnique}
                jobsLocation={jobsLocation || ''}
                setJobsLocation={setJobsLocation}
                handleSelectLocation={handleSelectLocation}
              />
              <div className='d-flex flex-column'>
                <h2 className='fs-16 lh-19 fw-600 mt-4 mb-md-0'>Áreas de atuação</h2>
                <AreasDeAtuacao
                  JobsContent={JobsContent}
                  handleClickAreas={handleClickAreas}
                  selectedAreas={selectedAreas}
                />
                <h2 className='fs-16 lh-19 fw-600 mt-4 mb-md-0'>Cargo</h2>
                <Cargo
                  JobsContent={JobsContent}
                  handleClickCargo={handleClickCargo}
                  selectedCargos={selectedCargos}
                />
              </div>
            </div>
            <S.HorizontalLine className='col-md-1 my-5 d-md-none' />
            <S.VerticalLine className='d-none d-block' />
            <div className='col-12 col-md-6 col-xl-7 offset-lg-0'>
              <JobList
                searchText={searchText}
                setSearchText={setSearchText}
                jobsLocation={jobsLocation}
                area={area}
                JobsContent={JobsContent}
                selectedAreas={selectedAreas}
                selectedCargos={selectedCargos}
                handleClose={handleClose}
                modal={modal}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Carreiras
