import styled from 'styled-components'
import breakpoints from '../../styles/breakpoints'
import { orange, grayscale } from '../../styles/colors'
import { Link } from 'gatsby'

export const Hero = styled.section`
  position: relative;

  h2 {
    color: #2E3038;
  }

  @media (min-width: ${breakpoints.md}) {
    min-height: 380px;
  }

  @media (min-width: ${breakpoints.lg}) {
    min-height: 520px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 640px;
  }

  .btn-scroll {
    width: 60%;
    bottom: 40px;
    left: 20%;
    right: 20%;
    position: absolute;

    @media (min-width: ${breakpoints.lg}) {
      bottom: 20px;
    }

    a {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        z-index: -5;
      }
    }
  }
`

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media (min-width: ${breakpoints.lg}) {
      width: 388px;
      float: right;
    }

    @media (min-width: ${breakpoints.xl}) {
      width: 468px;
    }
  }
`

export const BtnOrange = styled(Link)`
  border: 1px solid ${orange.extra};
  width: 100%;
  float: left;
`

export const HorizontalLine = styled.hr`
  border: 1px dashed ${grayscale[300]};
  width: 100%;
`

export const VerticalLine = styled.hr`
  border: 1px dashed ${grayscale[300]};
  height: auto;
`
